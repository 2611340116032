body {
  margin: 0;
  padding: 0;
  background: url('world_soup.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #1f1f1f;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
